<template>
	<div>
		<!--顶部工具条-->
		<el-col :span="24">
			<el-form :inline="true">
				<el-form-item label="名称:">
					<el-input v-model="filters.name" @input="(e) => (filters.name = utils.validForbid(e))" placeholder="请输入关键字" clearable @clear='clearContent'></el-input>
				</el-form-item>
				<el-form-item>
					<toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
				</el-form-item>
			</el-form>
		</el-col>
		<!--列表-->
		<el-table
			:data="dataPer"
			highlight-current-row 
			@current-change="selectCurrentRow"
			v-loading="listLoading"
			@selection-change="selsChange"
			style="width: 100%;"
		>
			<!-- <el-table-column type="selection" width="50">
			</el-table-column> -->
			<el-table-column type="index" width="80"></el-table-column>
			<el-table-column prop="name" label="数据权限名称" width="" sortable></el-table-column>
			<el-table-column prop="code" label="数据权限编码" width="" sortable></el-table-column>
			<el-table-column prop="remarks" label="备注" width="" sortable></el-table-column>
		</el-table>
		<!--新增-->
		<el-dialog title="新增" :visible.sync="addFormVisible" v-model="addFormVisible" :close-on-click-modal="false">
			<el-form :model="addForm" label-width="120px" :rules="addFormRules" ref="addForm">
				<el-form-item label="数据权限名称:" prop="Name">
					<el-input v-model="addForm.name" auto-complete="off"></el-input>
				</el-form-item>
				<el-form-item label="数据权限编码:" prop="Code">
					<el-input v-model="addForm.code" auto-complete="off"></el-input>
				</el-form-item>
				<el-form-item label="数据权限类型:">
					<el-checkbox-group v-model="addType"> 
						<el-checkbox label="全部" name='Type'></el-checkbox>
						<el-checkbox label="本部" name='Type'></el-checkbox>
						<el-checkbox label="本部及以下" name='Type'></el-checkbox>
						<el-checkbox label="本人" name='Type'></el-checkbox>
						<el-checkbox label="无" disabled name='Type'></el-checkbox>
					</el-checkbox-group>
				</el-form-item>
				<el-form-item label="备注:">
					<el-input type="textarea" v-model="addForm.remarks"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click.native="addFormVisible = false">取消</el-button>
				<el-button type="primary" @click.native="addSubmit" :loading="addLoading">提交</el-button>
			</div>
		</el-dialog>
		<!--编辑-->
		<el-dialog title="编辑" :visible.sync="editFormVisible" v-model="editFormVisible" :close-on-click-modal="false">
			<el-form :model="editForm" label-width="120px" :rules="editFormRules" ref="editForm">
				<el-form-item label="数据权限名称:" prop="Name">
					<el-input v-model="editForm.name" auto-complete="off"></el-input>
				</el-form-item>
				<el-form-item label="数据权限编码:" prop="Code">
					<el-input v-model="editForm.code" auto-complete="off"></el-input>
				</el-form-item>
				<el-form-item label="数据权限类型:">
					<el-checkbox-group v-model="editType"> 
						<el-checkbox label="全部" name='Type'></el-checkbox>
						<el-checkbox label="本部" name='Type'></el-checkbox>
						<el-checkbox label="本部及以下" name='Type'></el-checkbox>
						<el-checkbox label="本人" name='Type'></el-checkbox>
						<el-checkbox label="无" disabled name='Type'></el-checkbox>
					</el-checkbox-group>
				</el-form-item>
				<el-form-item label="备注:">
					<el-input type="textarea" v-model="editForm.remarks"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click.native="editFormVisible = false">取消</el-button>
				<el-button type="primary" @click.native="editSubmit" :loading="editLoading">提交</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import util from '../../../util/date';
import {getAllDataPermissionsInfoList,updateDataPermissionsInfo, addDataPermissionsInfo,deleteDataPermissionsInfo,} from '../../api/api';
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import Qs from 'qs'
export default {
	components: { Toolbar },
	data() {
		return {
			utils: '',
			dataPer:[],
			listLoading: false,
			currentRow: null,
			//列表选中列
			sels: [],
			//增删改查按钮
			buttonList: [],
			//顶部筛选条件
			filters: { 
				name: ''
			},
			//新增界面是否显示
			addFormVisible: false,
			addLoading: false,
			addFormRules: {
				name: [
					{required: true, message: '请输入数据权限名称', trigger: 'blur'}
				],
				code: [
					{required: true, message: '请输入数据权限编码', trigger: 'blur'}
				],
				type: [
					{required: true, message:'请选择数据权限类型', trigger:'change'}        
				]
			},
			//dialog数据
			addForm: {  
				name: '',
				code: '',
				id:'',
			},
			addType:['无'],   //新增数据权限类型
			//编辑界面是否显示
			editFormVisible: false,
			editLoading: false,
			editFormRules: {
				name: [
					{required: true, message: '请输入数据权限名称', trigger: 'blur'}
				],
				code: [
					{required: true, message: '请输入数据权限编码', trigger: 'blur'}
				],
				type: [
					{required: true, message:'请选择数据权限类型', trigger:'change'}
				]
			},
			//dialog数据
			editForm: {  
				name: '',
				code: '',
				id:'',
			},
			editType:["无"]  //编辑数据权限类型
		};
	},
	methods: {
		// 筛选框清空，重新获取数据
		clearContent(){
			this.getDataPermissions()
		},
		//当前行发生变化时
		selectCurrentRow(val) {
			this.currentRow = val;
		},
		//选项发生变化时
		selsChange: function (sels) {
			this.sels = sels;
		},
		//显示新增界面
		handleAdd(){
			this.addForm = {
				name: '',
				code: '',
				id:JSON.parse(localStorage.getItem("user")).id,
			};
			this.addType = ['无'],
			this.addFormVisible = true;
		},
		//显示编辑界面
		handleEdit(){
			let row = this.currentRow;
			this.editType = ["无"]
			if (!row) {
				this.$message({
					message: "请选择要编辑的一行数据！",
					type: "error"
				});
				return;
			}
			this.editForm = Object.assign({}, row);
			if(!row.isHideSelf){
				this.editType.push("本人")
			}
			if(!row.isHideOlnydept){
				this.editType.push("本部")
			}
			if(!row.isHideBelowdept){
				this.editType.push("本部及以下")
			}
			if(!row.isHideAll){
				this.editType.push("全部")
			}
			this.editFormVisible = true;
		},
		//新增
		addSubmit: function () {
			this.$refs.addForm.validate((valid) => {
				if (valid && this.addType.length > 0) {
					this.$confirm('确认提交吗？', '提示', {}).then(() => {
						this.addLoading = true;
						var isHideAllNum = 0
						var isHideOlnydeptNum = 0
						var isHideBelowdeptNum = 0
						var isHideSelfNum = 0
						for(var i = 0;i<this.addType.length;i++){
							if(this.addType[i] == "全部"){
								isHideAllNum++
								this.addForm.isHideAll = false
							}else if(isHideAllNum == 0){
								this.addForm.isHideAll = true
								isHideAllNum++
							}
							if(this.addType[i] == "本部"){
								this.addForm.isHideOlnydept = false
								isHideOlnydeptNum++
							}else if(isHideOlnydeptNum == 0){
								this.addForm.isHideOlnydept = true
								isHideOlnydeptNum++
							}
							if(this.addType[i] == "本部及以下"){
								this.addForm.isHideBelowdept = false
								isHideBelowdeptNum++
							}else if(isHideBelowdeptNum == 0){
								this.addForm.isHideBelowdept = true
								isHideBelowdeptNum++
							}
							if(this.addType[i] == "本人"){
								this.addForm.isHideSelf = false
								isHideSelfNum++
							}else if(isHideSelfNum == 0){
								this.addForm.isHideSelf = true
								isHideSelfNum++
							}
						}
						let params = Object.assign({}, this.addForm);
						addDataPermissionsInfo(params).then((res) => {
							if (util.isEmt.format(res)) {
								this.addLoading = false;
								return;
							}
							if (res.data.success) {
								this.addLoading = false;
								this.$message({
									message: res.data.message,
									type: 'success'
								});
								this.addFormVisible = false;
								this.getDataPermissions();
							} else {
								this.addLoading = false;
								this.$message({
									message: res.data.message,
									type: 'error'
								});
							}
						});
					});
				}else{
				this.$message({
					message: "请选择数据权限类型！",
					type: "error"
				});
				}
			});
		},
		//编辑
		editSubmit:function(){
			this.$refs.editForm.validate((valid) => {
				if (valid) {
					this.$confirm('确认提交吗？', '提示', {}).then(() => {
						this.editLoading = true;
						var isHideAllNum = 0
						var isHideOlnydeptNum = 0
						var isHideBelowdeptNum = 0
						var isHideSelfNum = 0
						for(var i = 0;i<this.editType.length;i++){
							if(this.editType[i] == "全部"){
								isHideAllNum++
								this.editForm.isHideAll = false
							}else if(isHideAllNum == 0){
								this.editForm.isHideAll = true
								isHideAllNum++
							}
							if(this.editType[i] == "本部"){
								this.editForm.isHideOlnydept = false
								isHideOlnydeptNum++
							}else if(isHideOlnydeptNum == 0){
								this.editForm.isHideOlnydept = true
								isHideOlnydeptNum++
							}
							if(this.editType[i] == "本部及以下"){
								this.editForm.isHideBelowdept = false
								isHideBelowdeptNum++
							}else if(isHideBelowdeptNum == 0){
								this.editForm.isHideBelowdept = true
								isHideBelowdeptNum++
							}
							if(this.editType[i] == "本人"){
								this.editForm.isHideSelf = false
								isHideSelfNum++
							}else if(isHideSelfNum == 0){
								this.editForm.isHideSelf = true
								isHideSelfNum++
							}
						}
						let params = Object.assign({}, this.editForm); 
						updateDataPermissionsInfo(params).then((res) => {
							if (util.isEmt.format(res)) {
								this.editLoading = false;
								return;
							}
							if (res.data.success) {
								this.editLoading = false;
								this.$message({
									message: res.data.message,
									type: 'success'
								});
								this.editFormVisible = false;
								this.getDataPermissions();
							}
							else {
								this.editLoading = false;
								this.$message({
									message: res.data.message,
									type: 'error'
								});
							}
						});
					});
				}
			})
		},
		//删除
		handleDel(){
			let row = this.currentRow;
			if (!row) {
				this.$message({
					message: "请选择要删除的一行数据！",
					type: "error"
				});
				return;
			}
			this.$confirm('确认删除该数据权限吗?', '提示', {
				type: 'warning'
			}).then(() => {
				this.listLoading = true;
				let params = {id: row.id};
				deleteDataPermissionsInfo(params).then((res) => {
					if (util.isEmt.format(res)) {
						this.listLoading = false;
						return;
					}
					if (res.data.success) {
						this.listLoading = false;
						this.$message({
							message: res.data.message,
							type: 'success'
						});
						this.userFormVisible = false;
						this.getDataPermissions();
					} else {
						this.listLoading = false;
						this.$message({
							message: res.data.message,
							type: 'error'
						});

					}
				});
			}).catch(() => {

			});
		},
		//获取数据权限列表
		getDataPermissions(){
			this.listLoading = true;
			let params = {
				name: this.filters.name,
			};
			getAllDataPermissionsInfoList(params).then((res) => {
				this.dataPer = res.data.response;
				this.listLoading = false;
			});
		},
		// 查询
		getDataPermissionsInfo() {
			this.getDataPermissions();
		},
		callFunction(item) {
			this[item.func].apply(this, item);
		}
	},
	mounted() {
		this.utils = util
		this.getDataPermissions();
		let routers = window.localStorage.router
		? JSON.parse(window.localStorage.router)
		: [];
		this.buttonList = getButtonList(this.$route.path, routers);
	}
};
</script>
<style lang="stylus" scoped>
.el-table>>>td, .el-table>>>th{
	padding: 8px!important;
}
</style>
